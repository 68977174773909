
.slide{
    
}
.slide img{
    height:100px;
    width:auto;
    margin:0;
    aspect-ratio: 3/1;
    object-fit:contain;
}