#articles {
    width: 1000px;
    max-width: 90vw;
    margin: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
}

.article {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 12px;
    margin: 17px;
    box-shadow: 0em 0em 1em rgb(174, 170, 172);
    margin-left: auto;
    margin-right: auto;
    transition: all.4s;
    overflow: hidden;
}

.article:hover {
    cursor: pointer;
}

.article .article_back {
    margin: 0;
    white-space: break-spaces;
    height: 50%;
    padding: 9px;
    width: calc(100% - 18px);
    color: var(--dark);
    background-size: cover;
    background-position: center;
    background-color: rgba(213, 23, 23, 0.5);
    
    border-radius: 12px 12px 0 0;
    border: none;
    font-size: 1.5em;
}

.article-ctn {
    height: 50%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: none;
}

.article a,
.article p {
    margin: 9px;
    padding: 0;
    line-height: normal;
    width: auto;
    font-weight: 300;
    font-size: medium;
    transition: all.1s ease .5s;
}

.article .tags {
    margin: 0;
    margin-top: -5vh;
    text-align: center;
    padding: 9px;
    width: calc(50% - 18px);
    height: 2vh;
    float: right;
    color: var(--light);
    text-transform: capitalize;
    background-color: rgb(0, 0, 0, .77);
}

.article .a-tags {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    text-align: center;
    padding: 9px 0;
    width: 100%;
    height: 25px;
    line-height: 25px;
    float: right;
    color: var(--dark-grey);
    text-transform: capitalize;
    font-weight: bold;
    background-color: var(--grey);
    border-radius: 0 0 12px 12px;
}

.article-ctn p {
    text-align: left;
    padding: 2%;
    font-size: 1.5em ;
    font-weight: bold ;
}
article_ctn_p{
    font-size: 1.5em ;
    font-weight: bold ;

}

@media only screen and (max-width:1400px) {

    .article h1 {
        max-width: none;
        margin: 0;
    }

}

@media only screen and (min-width:1100px) {



}

.bg-white {
    background-color: var(--light) !important;
}

.relative {
    position: relative !important;
}

.bg-title {
    position: absolute;
    bottom: 4%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 4em;
    font-weight: bold;
    color: var(--light);

}