.partenaires {
    background-image: url("../../images/vivatech.jpg") !important;
}

.bureau{
    background-image: url("../../images/bureau.jpg") ;
}

.bureau{
    background-image: url("../../images/bureau.jpg") ;
}


.partenaire_container {
    width: 70%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: 1fr 1fr 1fr; */
    column-gap: 70px;
    row-gap: 70px;
    justify-content: center;
}
.partenaire_container>* {
    flex: 0 0 26%;
}

.partenaire_profile {
    text-align: center;
    font-size: 15px;
    color: black;
    display:
        flex;
    flex-direction: column;
    gap: 20px;
}

.partenaire_profile img {
    width: auto;
    height: 120px;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.partenaire_profile_1 img {
    height: 250px;
}
.partenaire_profile_3 img {
    height: 150px;
}
.partenaire_profile_4 img {
    height: 70px;
}

@media screen and (max-width: 1400px) {
    .partenaire_container {
        grid-template-columns: 1fr;
        gap: 60px;
    }

    .partenaire_profile {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .partenaire_profile img {
        height: 100px;
    }

}


.div-info table {
    margin: 33px;
    width: 1000px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

th img {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

th img:hover {
    cursor: pointer;
}

@media only screen and (min-width:1400px) {

    th {
        padding: 2%;
        width: calc(100% / 3);
        min-width: 100px;
    }

}

@media only screen and (max-width:1400px) {

    tr {
        display: grid;
        max-width: 100vw !important;
    }

    .tr-mini {
        display: table !important;
    }

    th {
        padding: 0;
        margin: 14px;
        width: 90%;
        min-width: none;
        margin-left: auto;
        margin-right: auto;
    }

    .small {
        width: 60% !important;
        margin: 17px auto !important;
    }

}

@media only screen and (min-width: 500px) {

    .big-th {
        transform: scale(1.7);
    }

}

.big-width p img {
    transform: scale(1.1) translateY(17px);
    width: 100% !important;
}

.small-th {
    transform: scale(.81);
}