#Social {
    height: fit-content;
    min-height: 30vh;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    background-image: url("../../images/shell-2019.jpg");
    background-color: rgb(0, 0, 0, .7);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
}

.social-content {
    height: 100%;
    width: 750px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.social-content h1 {
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    color: var(--light);
    transform: skew(-20deg);
    border-left: .2em solid var(--red);
}

.social-btns {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
}

.social-btns a {
    height: 17vw;
    width: 17vw;
    max-height: 100px;
    max-width: 100px;
    margin-top: 5%;
    border: .1em solid var(--light);
    border-radius: 1em;
    line-height: min(22vw, 130px);
    margin-left: auto;
    margin-right: auto;
    transition: all.2s;
}

.social-btns a:hover {
    border-color: transparent;
    background-color: var(--light);
}

.social-btns a:hover .fa {
    color: var(--black);
}

.fa {
    color: var(--light);
    text-align: center;
    transition: all.2s;
    width: 100%;
    margin:auto;
}

@media only screen and (min-width:1400px) {

    .fa {
        font-size: min(8.5vw, 50px);
    }

    .social-btns a:hover {
        transform: scale(1.1);
    }

    .social-btns a:hover .fa {
        transform: rotateX(360deg);
    }

}

@media only screen and (max-width:1400px) {

    .fa {
        font-size: min(10vw, 70px);
    }

}