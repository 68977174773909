#Legal {
    width: 60%;
    margin: 250px auto 200px auto ;
    font-size: 20px;
}
@media all and (max-width: 800px) {
    #Legal {
        width: 90%;
        margin: 150px auto 100px auto ;
        font-size: 15px;
    }
}