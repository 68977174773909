.contact {
    position: relative;
    width: 1000px;
    max-width: 95%;
    margin: 50px;
    height: fit-content;
    min-height: 25vh;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width:1100px) {

    .pleft {
        width: 50%;
        transform: translateX(-20px);
    }

    .pright {
        position: absolute;
        padding: 2.5%;
        transform: translateX(15px);
        top: 0;
        right: 0;
        width: 45%;
        height: 95%;
        border-radius: 12px;
        background-image: url("../../images/image_form.png");
        background-position: center;
        background-size: cover;
    }

    input,
    textarea {
        color: #d9d6d6;
        border: .1em solid #d9d6d6;
    }


    input:focus,
    textarea:focus {
        border: .1em solid transparent;
        color: var(--dark-grey);
        border: .1em solid var(--dark-grey);
        box-shadow: 0em .2em .2em rgb(174, 170, 172);
    }

    .form-element-45 {
        flex: 0 0 45%;
    }

    .form-element-100 {
        flex: 0 0 96%;
    }

    .label {
        background-color: var(--light);
    }

}

@media only screen and (max-width:1100px) {

    .pleft {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        background-image: url("../../images/image_form.png");
        background-color: rgb(255, 255, 255, .7);
        background-blend-mode: lighten;
        background-position: center;
        background-size: cover;
    }

    .pleft h1 {
        margin: 4px 9px !important;
    }

    .pright {
        display: none;
    }

    input,
    textarea {
        color: var(--dark-grey);
        border: .1em solid var(--dark-grey);
    }

    .form-element-45,
    .form-element-100 {
        flex: 0 0 96%;
    }

    .label {
        top: -.7em !important;
        left: 0 !important;
        background-color: transparent !important;
    }

}

.pleft {
    height: 100%;
}

.pleft h1 {
    margin: 0;
    padding: 0;
    text-align: left;
    transform: none;
    border: none;
    width: 100%;
}

.pleft p {
    padding-left: 12px;
    border-left: .1em solid var(--red);
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form button {
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 9px 0;
    width: 95%;
    border: none;
    border-radius: 9px;
    background-color: var(--red);
    color: var(--light);
    font-size: 1.4em;
    transition: all.2s;
}

.form button:hover {
    cursor: pointer;
    filter: saturate(.8);
    transform: scale(.92);
}

.form button:active {
    animation: pressed .4s;
    animation-timing-function: linear;
}

@keyframes pressed {

    0% {
        transform: scale(.92);
    }

    30% {
        transform: scale(.9);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(.99);
    }

}

.form-element-45,
.form-element-100 {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.label {
    z-index: 2;
    font-size: 1em;
    color: var(--dark-grey);
}

input,
textarea {
    margin-top: 9px;
    margin-bottom: 9px;
    padding: .7em;
    font-family: 'Neo Sans Pro', sans-serif;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    transition: all.2s;
    animation: none;
}

input:invalid {
    border: .1em solid var(--red);
    color: var(--red);
    animation: shakeit .2s;
}

@keyframes shakeit {
    25% {
        transform: translateX(4px);
    }

    50% {
        transform: translateX(-4px);
    }

    75% {
        transform: translateX(4px);
    }
}

textarea {
    resize: none;
    height: 10vh;
    overflow: hidden;
    box-sizing: border-box;
}

input:focus,
textarea:focus {
    outline: none;
}

#message-form {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 2em 0;
    margin: 0;
    font-size: 2em;
    background-color: var(--red);
    color: var(--light);
    font-weight: bold;
    text-align: center;
}
